

div.tool-container {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    font-family: "MontserratMedium";
    font-size: medium;
    border-width: 2px;
}

.tool-input-label {
    font-family: "MontserratMedium";
    font-size: large;
    margin-right: 8px;
}