
div.about-container {
    padding-top: 1em;
    padding-bottom: 1em;
    font-family: "MontserratMedium";
    font-size: medium;
}

h2 {
    font-family: "MontserratMedium",sans-serif;
    font-size: x-large;
    padding-bottom: 5px;
    padding-top: 0;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .25rem;
}