@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: regular;
}

@font-face {
    font-family: "MontserratMedium";
    src: local("Montserrat Medium"),
        url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: medium;
}

@font-face {
    font-family: "MontserratSemiBold";
    src: local("Montserrat SemiBold"),
        url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: semibold;
}

@font-face {
    font-family: "MontserratExtraBold";
    src: local("Montserrat ExtraBold"),
        url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "MontserratLight";
    src: local("Montserrat Light"),
        url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: regular;
}

@font-face {
    font-family: "JSLBlackletter";
    src: local("JSLBlackletter"),
        url("./fonts/JslBlackletter/JBLACK.TTF") format("truetype");
}

@font-face {
    font-family: "OldNewsPaperTypes";
    src: local("OldNewspaperTypes"),
        url("./fonts/OldNewspaperTypes/Oldnewspapertypes-449D.ttf") format("truetype");
}

.font-face-montserrat-regular {
    font-family: "Montserrat";
}

.font-face-montserrat-medium {
    font-family: "MontserratMedium";
}

.font-face-montserrat-semibold {
    font-family: "MontserratSemiBold";
}

.font-face-montserrat-bold {
    font-family: "MontserratExtraBold";
}

.font-face-montserrat-light {
    font-family: "MontserratLight";
}

.font-face-blackletter {
    font-family: "JSLBlackletter";
}

.font-face-old-newspaper-types {
    font-family: "OldNewsPaperTypes";
}

div.twoColumns {
    column-count: 2;
}

h1.appTitle {
    font-family: "JSLBlackletter";
    font-size: 2.75em;
    margin-top: 1em;
}

.app {
    min-width: 56rem;
    max-width: 56rem;
    padding-left: 2.25em;
    padding-right: 2.25em;
}

.app-mobile {
    padding-left: 1em;
    padding-right: 1em;
}

a.regular {
    font-weight: bold;
    text-decoration-line: underline;
}

h2 {
    font-family: "MontserratMedium",sans-serif;
    font-size: x-large;
    padding-bottom: 5px;
    padding-top: 0;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .25rem;
}

h3 {
    font-family: "MontserratMedium",sans-serif;
    font-size: x-large;
    padding-bottom: 5px;
    padding-top: 0;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .25rem;
    margin-top: 4px;
}

h4 {
    font-family: "MontserratMedium",sans-serif;
    font-size: large;
    padding-bottom: 5px;
    padding-top: 0;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
}

