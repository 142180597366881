.shadowdark-map-container {
    min-width: 30rem;
    max-width: 30rem;
}

.shadowdark-site-size-selector {
    font-family: "MontserratMedium";
    font-size: large;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-width: 1px;
    padding: 3px;
    width: 150px;
}

