.npc-adventure-names-input {
    font-family: "MontserratMedium";
    font-size: large;
    border-radius: 5px;
    border-width: 1px;
    padding: 3px;
    width: 250px;
}

.npc-adventure-names-quantity-selector {
    font-family: "MontserratMedium";
    font-size: large;
    text-align: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-width: 1px;
    padding: 3px;
    width: 50px;
}

.npc-adventure-site-input {
    font-family: "MontserratMedium";
    font-size: large;
    border-radius: 5px;
    border-width: 1px;
    padding: 3px;
    width: 250px;
}

.npc-adventure-site-quantity-selector {
    font-family: "MontserratMedium";
    font-size: large;
    text-align: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-width: 1px;
    padding: 3px;
    width: 50px;
}
