.encounters-charisma-selector {
    font-family: "MontserratMedium";
    font-size: large;
    text-align: center;
    border-width: 1px;
    border-radius: 5px;
    padding: 3px;
    width: 50px;
}

.encounters-die-selector {
    font-family: "MontserratMedium";
    font-size: large;
    text-align: left;
    border-width: 1px;
    border-radius: 5px;
    padding: 3px;
    width: 100px;
}

li.encounters {
    margin: 0px 5px 10px 5px;
    font-family: "MontserratMedium";
    font-size: medium;
}


