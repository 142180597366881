.body-text {
    font-family: "MontserratMedium";
    font-size: medium;
    margin: 0px 5px 10px 0px;
    line-height: 1.75em;
}

.instruction-text {
    font-family: "MontserratMedium";
    font-size: small;
    margin: 0px 5px 10px 0px;
    line-height: 1.75em;
}


.body-label {
    font-family: "MontserratSemiBold";
    font-size: medium;
    margin: 0px 5px 10px 0px;
    line-height: 1.75em;
}

a.resources {
    font-weight: 400;
    text-decoration-line: underline;
}


div.resources {
    padding-top: 1em;
    padding-bottom: 1em;
    font-family: "MontserratMedium";
    font-size: medium;
}

.rightmost-selector {
    font-family: "MontserratMedium";
    font-size: large;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-width: 1px;
    padding: 3px;
    width: 150px;
}

